import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { StartupService } from "../../services/startup.service";
import { Constant } from "../../constant/constant";

@Component({
  selector: "approve-request",
  templateUrl: "./vta.approve-request.component.html",
  styleUrls: ["./vta.approve-request.component.scss"],
})
export class ApproveRequestComponent implements OnInit {
  requestId: string;
  loading: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private startup: StartupService,
    private router: Router,
    private constant: Constant
  ) {}

  ngOnInit(): void {
    const pathName: string = location?.pathname || "";

    this.activatedRoute.queryParams.subscribe((params) => {
      this.requestId = ((params || {}).requestid || "").replace(/\s+/g, "+");
      localStorage.setItem("vacationPreviewFrom", pathName);
      localStorage.setItem("vacationPreviewId", this.requestId);
    });

    this.redirectToPage();
  }

  redirectToPage() {
    if (
      this.startup.getNavigationData.loggedInStatus &&
      this.startup.getNavigationData.isTokenValid
    ) {
      this.router.navigateByUrl(this.constant.REDIRECT_LINKS.DASHBOARD);
    } else {
      this.router.navigateByUrl(this.constant.REDIRECT_LINKS.LOGIN);
    }
  }
}
