import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { NbToastrService } from "@nebular/theme";

import { Constant } from "../../constant/constant";
import { ApiService } from "../../services/api.service";

@Component({
  selector: "verify-email-page",
  styleUrls: ["./vta.verify-email.component.scss"],
  templateUrl: "./vta.verify-email.component.html",
})
export class VerifyEmailComponent implements OnInit {
  public verificationId: string = "";

  constructor(
    protected router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: NbToastrService,
    private apiService: ApiService,
    private constant: Constant
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      console.log(params, "PARAMS");
      this.verificationId = ((params || {}).verifyid || "").replace(
        /\s+/g,
        "+"
      );

      this.verifyEmail(this.verificationId);
    });
  }

  verifyEmail(token: string) {
    this.apiService.verifyEmail(token).subscribe({
      next: (data) => {
        this.showToast(
          this.constant.TOAST_ACTION.SUCCESS,
          this.constant.RESET_PASSWORD_MSG.EMAIL_VERIFIED
        );
        this.redirectToLogin();
      },
      error: (err) => {
        this.showToast(
          this.constant.TOAST_ACTION.FAILURE,
          this.constant.RESET_PASSWORD_MSG.EMAIL_VERIFICATION_FAILURE
        );
        this.redirectToLogin();
      },
    });
  }

  private redirectToLogin() {
    const url = this.constant.REDIRECT_LINKS.LOGIN;
    this.router.navigateByUrl(url);
  }

  showToast(status: string, msg: string) {
    this.toastrService.show("", msg, { status });
  }
}
