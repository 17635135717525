import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpRequest } from "@angular/common/http";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule, ActionReducer, MetaReducer } from "@ngrx/store";

import {
  NbAlertModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbSpinnerModule,
} from "@nebular/theme";

import {
  defaultAuthOptions,
  NB_AUTH_INTERCEPTOR_HEADER,
  NB_AUTH_OPTIONS,
  NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
  NB_AUTH_USER_OPTIONS,
  NbAuthOptions,
} from "./auth.options";

import { NbAuthComponent } from "./components/auth.component";

import { NbAuthBlockComponent } from "./components/auth-block/auth-block.component";
import { LoginComponent } from "./components/login/vta.login.component";
import { RegisterComponent } from "./components/register/vta.register.component";
import { NbLogoutComponent } from "./components/logout/logout.component";
import { RequestPasswordComponent } from "./components/request-password/vta.request-password.component";
import { ButtonModule } from "@syncfusion/ej2-angular-buttons";
import { TextBoxModule } from "@syncfusion/ej2-angular-inputs";
import { localStorageSync } from "ngrx-store-localstorage";

import { ApiService } from "./services/api.service";
import { AuthenticationEffects } from "./store/effects/authentication.effects";
import { reducers } from "./store/app.states";
import { deepExtend } from "./helpers";
import { ResetPasswordComponent } from "./components/reset-password/vta.reset-password.component";
import { VerifyUserComponent } from "./components/verify-user/vta.verify-user.component";
import { ApproveRequestComponent } from "./components/approve-request/vta.approve-request.component";
import { ProgressButtonModule } from "@syncfusion/ej2-angular-splitbuttons";
import { VerifyEmailComponent } from "./components/verify-email/vta.verify-email.component";

export function nbOptionsFactory(options) {
  return deepExtend(defaultAuthOptions, options);
}

export function nbNoOpInterceptorFilter(req: HttpRequest<any>): boolean {
  return true;
}

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({ keys: ["authentication"], rehydrate: true })(
    reducer
  );
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  imports: [
    CommonModule,
    NbLayoutModule,
    NbCardModule,
    NbCheckboxModule,
    NbAlertModule,
    NbInputModule,
    NbButtonModule,
    RouterModule,
    FormsModule,
    NbIconModule,
    NbSpinnerModule,
    EffectsModule.forRoot([AuthenticationEffects]),
    StoreModule.forRoot(reducers, { metaReducers }),
    TextBoxModule,
    ReactiveFormsModule,
    ButtonModule,
    ProgressButtonModule,
  ],
  declarations: [
    NbAuthComponent,
    NbAuthBlockComponent,
    LoginComponent,
    RegisterComponent,
    RequestPasswordComponent,
    ResetPasswordComponent,
    VerifyUserComponent,
    VerifyEmailComponent,
    NbLogoutComponent,
    ApproveRequestComponent,
  ],
  exports: [
    NbAuthComponent,
    NbAuthBlockComponent,
    LoginComponent,
    RegisterComponent,
    RequestPasswordComponent,
    ResetPasswordComponent,
    VerifyUserComponent,
    VerifyEmailComponent,
    NbLogoutComponent,
  ],
})
export class NbAuthModule {
  static forRoot(
    nbAuthOptions?: NbAuthOptions
  ): ModuleWithProviders<NbAuthModule> {
    return {
      ngModule: NbAuthModule,
      providers: [
        { provide: NB_AUTH_USER_OPTIONS, useValue: nbAuthOptions },
        {
          provide: NB_AUTH_OPTIONS,
          useFactory: nbOptionsFactory,
          deps: [NB_AUTH_USER_OPTIONS],
        },
        { provide: NB_AUTH_INTERCEPTOR_HEADER, useValue: "Authorization" },
        {
          provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
          useValue: nbNoOpInterceptorFilter,
        },
        ApiService,
      ],
    };
  }
}
