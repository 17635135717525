import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class Constant {
  REGISTER_MSG = {
    SUCCESS:
      "Registration successful. We have sent a verification email. Please follow instructions on that email.",
    FAILURE: "Registration failed",
  };

  REQUEST_PASSWORD_MSG = {
    SUCCESS:
      "We have sent a reset password link email. Please follow instructions on that email.",
    FAILURE: "Reset password link failed",
  };

  RESET_PASSWORD_MSG = {
    SUCCESS: "Your password has been updated successfully.",
    FAILURE: "New password updation failed.",
    ALREADY_VERIFIED:
      "User already verified. Please login with your credentials.",
    EMAIL_VERIFIED: "Email verified successfully",
    EMAIL_VERIFICATION_FAILURE: "Email verification failed",
  };

  REDIRECT_LINKS = {
    EMPLOYEE_ROUTE: (id) => "/pages/employees/" + id,
    DEPARTMENT_ROUTE: (id) => "/pages/departments/" + id,
    DEPARTMENTS: "/pages/departments",
    EMPLOYEES: "/pages/employees",
    LOGIN: "/login",
    PROFILE: "/pages/profile",
    DASHBOARD: "/pages/dashboard",
    ORGANIZATION: "/pages/organization",
    REGISTER: "/register",
  };

  DEPARTMENT_CRUD_MSG = {
    DEPARTMENT_ADD_SUCCESS: "Department added successfully",
    DEPARTMENT_ADD_FAILURE: "Department addition failed",
    DEPARTMENT_UPDATE_SUCCESS: "Department updated successfully",
    DEPARTMENT_UPDATE_FAILURE: "Department updation failed",
  };

  TOAST_ACTION = {
    SUCCESS: "success",
    FAILURE: "danger",
  };

  EMPLOYEE_CRUD_MSG = {
    EMPLOYEE_ADD_SUCCESS: "Employee added successfully",
    EMPLOYEE_ADD_FAILURE: "Employee addition failed",
    EMPLOYEE_UPDATE_SUCCESS: "Employee updated successfully",
    EMPLOYEE_UPDATE_FAILURE: "Employee updation failed",
    EMPLOYEE_ADD_POLICY_SUCCESS: "Employee Time Off Policy added successfully",
    EMPLOYEE_TERMINATION_FAILURE:
      "Employee updation failed, and the employee cannot be terminated since other employees report to them.",
  };

  USER_MENU = {
    PROFILE: "Profile",
    LOG_OUT: "Log out",
  };

  USER_CRUD_MSG = {
    UPDATE_SUCCESS: "User updated successfully",
  };

  OVERLAP_VACATION = {
    FULL_DAY_OVERLAP:
      "Your vacation request conflicts with an existing planned / approved vacation on {date}. Please choose a different date.",
    PARTIAL_DAY_OVERLAP:
      "Your vacation request overlaps with an existing planned / approved vacation from {start} to {end}. Please review and resubmit your request.",
    MULTIPLE_REQUEST_OVERLAP:
      "Your request overlaps with multiple planned / approved vacations. Please review all dates and resubmit your request.",
    PROXY_REQUEST_OVERLAP:
      "The vacation request submitted on behalf of the employee overlaps with their existing planned / approved leave. Please review and resubmit your request.",
    DIFFERENT_VACATION_OVERLAP:
      "Your vacation request overlaps with existing {vacationType}. Please review the dates and resubmit your request.",
  };

  API_URLS = {
    LOGIN: "/user/auth",
    GET_USER: (id) => "/user/" + id,
    CHANGE_PASSWORD: (id) => "/user/" + id + "/changepassword",
    RESET_PASSWORD: "/user/resetpassword",
    DASHBOARD: "/pages/dashboard",
    FORGOT_PASSWORD: "/user/forgotpassword",
    CREATE_ORGANIZATION: "/organization",
    UPDATE_USER: (id) => "/user/" + id,
    VERIFY_USER: "/user/verify",
    VERIFY_EMAIL: "/employee/verify/email",
    ADD_EMPLOYEE: "/employee",
    GET_EMPLOYEE: "/employee",
    UPDATE_EMPLOYEE: (id) => "/employee/" + id,
    UPDATE_EMPLOYEE_EMAIL: () => "/employee/email",
    ADD_EMPLOYEE_VACATION_POLICY: (id) =>
      "/employee/" + id + "/vacationpolicies",
    GET_EMPLOYEE_VACATION_POLICY: (id) =>
      "/employee/" + id + "/vacationpolicies",
    GET_EMPLOYEE_VACATION_POLICY_DETAILS: (id, vacId) =>
      "/employee/" + id + "/vacationpolicy/" + vacId,
    UPDATE_EMPLOYEE_VACATION_POLICY: (id, vacId) =>
      "/employee/" + id + "/vacationpolicy/" + vacId,
    GET_EMPLOYEE_INFO: (id) => "/employee/" + id,
    GET_ALL_EMPLOYEES: (id: string, qString: string) =>
      qString
        ? `/employee/${id}/employees?${qString}`
        : `/employee/${id}/employees`,
    RESEND_VERIFICATION_EMAIL: "/employee/resendVerifyEmail",
    GET_ORGANIZATION: (id) => "/organization/" + id,
    GET_ORG_CHART_DATA: (id) => `/organization/${id}/orgchart`,
    UPDATE_ORGANIZATION: (id) => "/organization/" + id,
    UPDATE_ORGANIZATION_ADDRESS: (id) => "/organization/" + id + "/address",
    GET_ORGANIZATION_ADDRESS: (id) => "/organization/" + id + "/address",
    GET_ALL_DEPARTMENTS: (id) => "/organization/" + id + "/departments",
    GET_DEPARTMENT_INFO: (id) => "/department/" + id,
    ADD_DEPARTMENT: "/department",
    UPDATE_DEPARTMENT: (id) => "/department/" + id,
    GET_DEPARTMENT_EMPLOYEES: (id) => "/department/" + id + "/employees",
    UPDATE_ORGANIZATION_TIME_OFF_TYPE: (id) =>
      "/organization/" + id + "/timeofftypes",
    ADD_ORGANIZATION_VACATION_POLICY: (id) =>
      "/organization/" + id + "/vacationpolicy",
    UPDATE_ORGANIZATION_VACATION_POLICY: (orgId, id) =>
      "/organization/" + orgId + "/vacationpolicy/" + id,
    GET_VACATION_TYPES: (id) => "/organization/" + id + "/timeofftypes",
    GET_VACATION_POLICIES: (id) => "/organization/" + id + "/vacationpolicies",
    GET_ORGANIZATION_SETTINGS: (id) => "/organization/" + id + "/othersettings",
    UPDATE_ORGANIZATION_SETTINGS: (id) =>
      "/organization/" + id + "/othersettings",
    GET_ORGANIZATION_STATUTORY_HOLIDAYS: (id) =>
      "/organization/" + id + "/statdaysoff/v2",
    UPDATE_ORGANIZATION_STATUTORY_HOLIDAYS: (id) =>
      "/organization/" + id + "/statdaysoff/v2",
    GET_EMPLOYEE_TIME_OFF_TYPES: (id) => "/employee/" + id + "/TimeoffTypes",
    UPDATE_EMPLOYEE_TIME_OFF_TYPES: (id) => "/employee/" + id + "/TimeoffTypes",
    GET_EMPLOYEE_ADDRESS: (id) => "/employee/" + id + "/address",
    UPDATE_EMPLOYEE_ADDRESS: (id) => "/employee/" + id + "/address",
    CREATE_VACATION: "/vacation",
    CREATE_VACATION_PROXY: "/vacation/proxy",
    GET_VACATION_REQUEST_FROM_EMAIL: "/vacation/request/",
    GET_VACATION_REQUEST: (id) => "/vacation/request/" + id,
    APPROVE_VACATION_REQUEST: (id) => "/vacation/approve/" + id,
    REJECT_VACATION_REQUEST: (id) => "/vacation/reject/" + id,
    CANCEL_VACATION_REQUEST: (id) => "/vacation/cancel/" + id,
    GET_ALL_VACATION_HISTORY: (id) =>
      "/vacation/history?empid=" + id + "&accrued=1",
    GET_ALL_VACATION_HISTORY_WO_ACCRUED: (id) =>
      "/vacation/history?empid=" + id,
    GET_ALL_TEAM_VACATION_HISTORY: (id, fromDate, toDate) =>
      "/vacation/history?empid=" +
      id +
      "&team=1&from=" +
      fromDate +
      "&to=" +
      toDate,
    GET_ORGANIZATION_SCHEDULER_DATA: (id) =>
      "/vacation/history?orgid=" + id + "&status=2",
    GET_ORG_SCHEDULER_DATA: (id) =>
      "/vacation/history?orgid=" + id + "&status=2",
    GET_MYTEAM_SCHEDULER_DATA: (empId) =>
      "/vacation/history?reportingto=" + empId + "&status=2",
    GET_APPROVAL_REQUEST: (id) =>
      "/vacation/history?reportingto=" + id + "&status=1",
    GET_COUNTRY_STAT_DAYS: (country, state) =>
      "/vacation/holidays?country=" + country + "&state=" + state,
    ADD_STAT_HOLIDAY: (id) => "/organization/" + id + "/statdaysoff/v2",
    GET_COUNTRY_AND_STATE: "/vacation/holidays/countries",
    GET_VACATION_DAYS: (fromDate, toDate) =>
      "/vacation/getdays?from=" + fromDate + "&to=" + toDate,
    GET_REMAINING_VACATION_DAYS: "/vacation/getvacationdays",
    GET_REMAINING_VACATION_DAYS_ON_VACATION_DATE: (fromDate) =>
      "/vacation/getvacationdays?vacationdate=" + fromDate,
    GET_EMPLOYEE_REMAINING_VACATION_DAYS: (id: string) =>
      `/vacation/getvacationdays?employee=${id}`,
    GET_EMPLOYEE_REMAINING_VACATION_DAYS_ON_VACATION_DATE: (
      employee,
      fromDate: string,
      allowEndDate: boolean,
      toDate: string
    ) =>
      allowEndDate
        ? `/vacation/getvacationdays?vacationdate=${fromDate}&enddate=${toDate}&employee=${employee}`
        : `/vacation/getvacationdays?vacationdate=${fromDate}&employee=${employee}`,
    GET_ALL_ORG_VACATION_HISTORY: (id) => "/vacation/history?orgid=" + id,
    GET_ALL_ORG_VACATION_HISTORY_ACCRUED: (id) =>
      "/vacation/history?orgid=" + id + "&accrued=1",
    GET_UPCOMING_TIME_OFFS: (id) =>
      "/vacation/history?empid=" + id + "&status=2",
    GET_ORGANIZATION_EMPLOYEES: (id) => "/organization/" + id + "/employees",
  };

  DOMAIN_URLS = {
    DEV: "https://dev.easyvta.com",
    STAGING: "https://staging.easyvta.com",
    PRODUCTION: "https://easyvta.com",
    LOCAL: "http://localhost:4200",
  };

  GENERIC_ERROR_MSG = {
    UNAUTHORIZED: "User not authorized",
    FORBIDDEN: "Access forbidden",
  };

  TIME_OFF_UNAUTHORIZED_MSG = {
    MSG: "Not authorized to access this vacation",
    MSG_TO_DISPLAY: "You are not authorized to access this vacation",
  };

  HTTP_CODES = {
    CONTINUE: 100,
    SWITCHING_PROTOCOLS: 101,
    PROCESSING: 102,
    HEURISTIC_EXPIRATION: 113,
    MISCELLANEOUS_WARNING: 199,
    OK: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NON_AUTHORITATIVE_INFORMATION: 203,
    NO_CONTENT: 204,
    RESET_CONTENT: 205,
    PARTIAL_CONTENT: 206,
    MULTI_STATUS: 207,
    ALREADY_REPORTED: 208,
    TRANSFORMATION_APPLIED: 214,
    IM_USED: 226,
    MISCELLANEOUS_PERSISTENT_WARNING: 299,
    MULTIPLE_CHOICES: 300,
    MOVED_PERMANENTLY: 301,
    FOUND: 302,
    SEE_OTHER: 303,
    NOT_MODIFIED: 304,
    USE_PROXY: 305,
    UNUSED: 306,
    TEMPORARY_REDIRECT: 307,
    PERMANENT_REDIRECT: 308,
    TOO_MANY_REDIRECTS: 310,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    PAYMENT_REQUIRED: 402,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    METHOD_NOT_ALLOWED: 405,
    NOT_ACCEPTABLE: 406,
    PROXY_AUTHENTICATION_REQUIRED: 407,
    REQUEST_TIMEOUT: 408,
    CONFLICT: 409,
    GONE: 410,
    LENGTH_REQUIRED: 411,
    PRECONDITION_FAILED: 412,
    PAYLOAD_TOO_LARGE: 413,
    URI_TOO_LONG: 414,
    UNSUPPORTED_MEDIA_TYPE: 415,
    RANGE_NOT_SATISFIABLE: 416,
    EXPECTATION_FAILED: 417,
    IM_A_TEAPOT: 418,
    MISDIRECT_REQUEST: 421,
    UNPROCESSABLE_ENTITY: 422,
    LOCKED: 423,
    FAILED_DEPENDENCY: 424,
    UNORDERED_COLLEcTION: 425,
    UPGRADE_REQUIRED: 426,
    PRECONDITION_REQUIRED: 428,
    TOO_MANY_REQUEST: 429,
    REQUEST_HEADER_FIELDS_TOO_LARGE: 431,
    INTERNAL_SERVER_ERROR: 500,
    NOT_IMPLEMENTED: 501,
    BAD_GATEWAY: 502,
    SERVICE_UNAVAILABLE: 503,
    GATEWAY_TIMEOUT: 504,
    HTTP_VERSION_NOT_SUPPORTED: 505,
    VARIANT_ALSO_NEGOTIATES: 506,
    INSUFFICIENT_STORAGE: 507,
    LOOP_DETECTED: 508,
    NOT_EXTENDED: 510,
    NETWORK_AUTHENTICATION_REQUIRED: 511,
  };

  RESEND_VERIFICATION_EMAIL_MSG = {
    SUCCESS: "Successfully sent an verification email.",
    FAILURE: "Failed to send verification email",
  };

  ORGANIZATION_CRUD_MSG = {
    ORGANIZATION_ADD_SUCCESS: "Organization added successfully",
    ORGANIZATION_ADD_FAILURE: "Organization addition failed",
    ORGANIZATION_UPDATE_SUCCESS: "Organization updated successfully",
    ORGANIZATION_UPDATE_FAILURE: "Organization updation failed",
  };

  USER_ROLES = {
    ADMIN: "admin",
    TEAM_ADMIN: "teamAdmin",
    EMP: "employee",
  };

  USER_ROLE_MAP = {
    [this.USER_ROLES.ADMIN]: "Admin",
    [this.USER_ROLES.TEAM_ADMIN]: "Team Admin",
    [this.USER_ROLES.EMP]: "Employee",
  };

  UNAUTHORIZED = {
    MSG: "Unauthorized to perform this action",
  };

  TIME_OFF_CRUD_MSG = {
    TIME_OFF_ADD_SUCCESS: "Time-Off added successfully",
    TIME_OFF_ADD_FAILURE: "Time-Off addition failed",
    TIME_OFF_UPDATE_SUCCESS: "Time-Off updated successfully",
    TIME_OFF_UPDATE_FAILURE: "Time-Off updation failed",
  };

  VACATION_POLICY_CRUD_MSG = {
    VACATION_POLICY_ADD_SUCCESS: "Time Off Policy added successfully",
    VACATION_POLICY_ADD_FAILURE: "Time Off Policy addition failed",
    VACATION_POLICY_UPDATE_SUCCESS: "Time Off Policy updated successfully",
    VACATION_POLICY_UPDATE_FAILURE: "Time Off Policy updation failed",
  };

  ORGANIZATION_SETTING_MSG = {
    ORGANIZATION_SETTING_SUCCESS: "Organization setting updated successfully",
    ORGANIZATION_SETTING_FAILURE: "Organization setting  updation failed",
  };

  ORGANIZATION_STATUTORY_MSG = {
    ORGANIZATION_STATUTORY_SUCCESS:
      "Organization statutory holiday updated successfully",
    ORGANIZATION_STATUTORY_FAILURE:
      "Organization statutory holiday updation failed",
    ORGANIZATION_STATUTORY_ADD_SUCCESS:
      "Organization statutory holiday added successfully",
    ORGANIZATION_STATUTORY_ADD_FAILURE:
      "Organization statutory holiday addition failed",
  };

  EMPLOYEE_ADDRESS_MSG = {
    ADDRESS_UPDATE_SUCCESS: "Emplyee address updated successfully",
    ADDRESS_UPDATE_FAILURE: "Emplyee address updation failed",
  };

  EMPLOYEE_TIME_OFF_MSG = {
    TIME_OFF_UPDATE_SUCCESS: "Emplyee Time-Off updated successfully",
    TIME_OFF_UPDATE_FAILURE: "Emplyee Time-Off updation failed",
  };

  VACATION_MSG = {
    CREATE_SUCCESS: "Time Off request submitted successfully",
    CREATE_FAILURE: "Time Off request submition failed",
    DAYS_REQUIRED: "Time Off Days are required.",
  };

  VACATION_REQUEST_MSG = {
    APPROVE_SUCCESS: "Time Off request approved successfully",
    APPROVE_FAILURE: "Time Off request approve failed",
    REJECT_SUCCESS: "Time Off request rejected successfully",
    REJECT_FAILURE: "Time Off request rejection failed",
    CANCEL_SUCCESS: "Time Off request canceled successfully",
    CANCEL_FAILURE: "Time Off request cancelation failed",
  };

  REQUEST_STATUS = {
    1: "Requested",
    2: "Approved",
    3: "Rejected",
    4: "Cancelled",
    5: "Accrued",
    6: "Carried Over",
    7: "Carried Over on OB",
    8: "Used On OB",
    9: "Lost",
  };

  REQUEST_STATUS_CODES = {
    Requested: 1,
    Approved: 2,
    Rejected: 3,
    Cancelled: 4,
    Accrued: 5,
    CarriedOver: 6,
    CarriedOverOnOB: 7,
    UsedOnOB: 8,
    Lost: 9,
  };

  EMAIL_VALIDATION_PATTERN = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
}
