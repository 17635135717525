import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { User } from "../models/vta.user";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import { Constant } from "../constant/constant";
import { TOrgChartEmployee } from "../../../../@core/data/users";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  baseUrl: string;

  constructor(private http: HttpClient, private constant: Constant) {
    if (location.origin == constant.DOMAIN_URLS.DEV) {
      this.baseUrl = environment.dev_url;
    } else if (location.origin == constant.DOMAIN_URLS.STAGING) {
      this.baseUrl = environment.staging_url;
    } else if (location.origin == constant.DOMAIN_URLS.PRODUCTION) {
      this.baseUrl = environment.production_url;
    } else if (location.origin == constant.DOMAIN_URLS.LOCAL) {
      this.baseUrl = environment.dev_url;
    }
  }

  getToken(): string {
    let userInfo = JSON.parse(localStorage.getItem("authentication"));
    return ((userInfo || {}).user || {}).token || "";
  }

  isLoggedIn() {
    const token = this.getToken();
    return token != null;
  }

  getHttpOptions() {
    let token = this.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };
    return httpOptions;
  }

  getHttpOptionsUserVerify(token: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };
    return httpOptions;
  }

  getHttpOptionsTimeOffRequestApprove(token: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };
    return httpOptions;
  }

  getLoginHttpOptions(email: string, password: string) {
    return {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Basic " + btoa(email + ":" + password),
      }),
    };
  }

  login(email: string, password: string): Observable<any> {
    let httpOptions = this.getLoginHttpOptions(email, password);
    let url = this.constant.API_URLS.LOGIN;
    return this.http.post<User>(
      this.baseUrl + url,
      { email, password },
      httpOptions
    );
  }

  createOrganization(payload: any): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.CREATE_ORGANIZATION;
    return this.http.post<any>(this.baseUrl + url, payload, httpOptions);
  }

  requestPassword(payload: any): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.FORGOT_PASSWORD;
    return this.http.post<any>(this.baseUrl + url, payload, httpOptions);
  }

  resetPassword(payload: any): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.RESET_PASSWORD;
    return this.http.post<any>(this.baseUrl + url, payload, httpOptions);
  }

  createEmployee(payload: any): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.ADD_EMPLOYEE;
    return this.http.post<any>(this.baseUrl + url, payload, httpOptions);
  }

  createDepartment(payload: any): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.ADD_DEPARTMENT;
    return this.http.post<any>(this.baseUrl + url, payload, httpOptions);
  }

  changePassword(id: string, payload: any): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.CHANGE_PASSWORD(id);
    return this.http.post<any>(this.baseUrl + url, payload, httpOptions);
  }

  getUserInformation(id: string): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_USER(id);
    return this.http.get<User>(this.baseUrl + url, httpOptions);
  }

  updateUserInformation(payload: any, id: string): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.UPDATE_USER(id);
    return this.http.put<User>(this.baseUrl + url, payload, httpOptions);
  }
  verifyUser(payload: any, token: string): Observable<any> {
    let httpOptions = this.getHttpOptionsUserVerify(token);
    let url = this.constant.API_URLS.VERIFY_USER;
    return this.http.post<any>(this.baseUrl + url, payload, httpOptions);
  }

  verifyEmail(token: string): Observable<any> {
    let httpOptions = this.getHttpOptionsUserVerify(token);
    let url = this.constant.API_URLS.VERIFY_EMAIL;
    return this.http.post<any>(this.baseUrl + url, {}, httpOptions);
  }

  getEmployee(): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_EMPLOYEE;
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  getEmployeeInformation(id): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_EMPLOYEE_INFO(id);
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  getDepartmentInformation(id): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_DEPARTMENT_INFO(id);
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  updateEmployee(payload: any, id: string): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.UPDATE_EMPLOYEE(id);
    return this.http.put<any>(this.baseUrl + url, payload, httpOptions);
  }

  updateEmployeeEmail(payload: {
    email: string;
    empId: string;
  }): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.UPDATE_EMPLOYEE_EMAIL();
    return this.http.put<any>(this.baseUrl + url, payload, httpOptions);
  }

  addEmployeeVacationPolicy(payload: any, id: string): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.ADD_EMPLOYEE_VACATION_POLICY(id);
    return this.http.post<any>(this.baseUrl + url, payload, httpOptions);
  }
  updateEmployeeVacationPolicy(
    payload: any,
    id: string,
    vacId: string
  ): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.UPDATE_EMPLOYEE_VACATION_POLICY(id, vacId);
    return this.http.put<any>(this.baseUrl + url, payload, httpOptions);
  }
  getEmployeeVacationPolicies(id: string): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_EMPLOYEE_VACATION_POLICY(id);
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  getEmployeeVacationPolicyDetails(id: string, vacId: string): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_EMPLOYEE_VACATION_POLICY_DETAILS(
      id,
      vacId
    );
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  getAllEmployees(id: string, qString: string): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_ALL_EMPLOYEES(id, qString);
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  getAllDepartments(id: string): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_ALL_DEPARTMENTS(id);
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  resendVerificationEmail(payload: any, id: string): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.RESEND_VERIFICATION_EMAIL;
    return this.http.post<any>(this.baseUrl + url, payload, httpOptions);
  }

  getOrgnizationInfo(id: string): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_ORGANIZATION(id);
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  getOrgChartData(id: string): Observable<TOrgChartEmployee[]> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_ORG_CHART_DATA(id);
    return this.http.get<TOrgChartEmployee[]>(this.baseUrl + url, httpOptions);
  }

  updateOrgChart(
    id: string,
    payload: {
      id: string;
      role: string;
      reportingTo: string;
    }[]
  ): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_ORG_CHART_DATA(id);
    return this.http.patch<any>(this.baseUrl + url, payload, httpOptions);
  }

  updateOrganization(id: string, payload: any): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.UPDATE_ORGANIZATION(id);
    return this.http.put<any>(this.baseUrl + url, payload, httpOptions);
  }

  getOrganizationAddress(id: string): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_ORGANIZATION_ADDRESS(id);
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  updateOrganizationAddress(id: string, payload: any): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.UPDATE_ORGANIZATION_ADDRESS(id);
    return this.http.put<any>(this.baseUrl + url, payload, httpOptions);
  }

  updateDepartment(payload: any, id: string): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.UPDATE_DEPARTMENT(id);
    return this.http.put<any>(this.baseUrl + url, payload, httpOptions);
  }

  getDepartmentEmployees(id: string) {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_DEPARTMENT_EMPLOYEES(id);
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }
  addTimeOffType(payload: any, id: string) {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.UPDATE_ORGANIZATION_TIME_OFF_TYPE(id);
    return this.http.put<any>(this.baseUrl + url, payload, httpOptions);
  }

  addVacationPolicy(payload: any, id: string) {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.ADD_ORGANIZATION_VACATION_POLICY(id);
    return this.http.post<any>(this.baseUrl + url, payload, httpOptions);
  }

  updateVacationPolicy(payload: any, orgId: string, id: string) {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.UPDATE_ORGANIZATION_VACATION_POLICY(
      orgId,
      id
    );
    return this.http.put<any>(this.baseUrl + url, payload, httpOptions);
  }

  deleteVacationPolicy(orgId: string, id: string) {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.UPDATE_ORGANIZATION_VACATION_POLICY(
      orgId,
      id
    );
    return this.http.delete<any>(this.baseUrl + url, httpOptions);
  }

  getAllVacationTypes(id: string) {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_VACATION_TYPES(id);
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  getAllVacationPolicies(id: string) {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_VACATION_POLICIES(id);
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  getOrganizationSetting(id: string) {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_ORGANIZATION_SETTINGS(id);
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  updateOrganizationSetting(payload: any, id: string) {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.UPDATE_ORGANIZATION_SETTINGS(id);
    return this.http.put<any>(this.baseUrl + url, payload, httpOptions);
  }

  getStatutoryDaysOff(id: string) {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_ORGANIZATION_STATUTORY_HOLIDAYS(id);
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  updateStatutoryDaysOff(payload: any, id: string) {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.UPDATE_ORGANIZATION_STATUTORY_HOLIDAYS(id);
    return this.http.put<any>(this.baseUrl + url, payload, httpOptions);
  }

  getEmployeeTimeoffTypes(id: string) {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_EMPLOYEE_TIME_OFF_TYPES(id);
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  updateEmployeeTimeoffTypes(payload: any, id: string) {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.UPDATE_EMPLOYEE_TIME_OFF_TYPES(id);
    return this.http.put<any>(this.baseUrl + url, payload, httpOptions);
  }

  getEmployeeAddress(id: string): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_EMPLOYEE_ADDRESS(id);
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  updateEmployeeAddress(id: string, payload: any): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.UPDATE_EMPLOYEE_ADDRESS(id);
    return this.http.put<any>(this.baseUrl + url, payload, httpOptions);
  }

  createVacation(payload: any, isProxy: boolean): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = isProxy
      ? this.constant.API_URLS.CREATE_VACATION_PROXY
      : this.constant.API_URLS.CREATE_VACATION;
    return this.http.post<any>(this.baseUrl + url, payload, httpOptions);
  }

  getVacationRequestFromEmail(token: string): Observable<any> {
    let httpOptions = this.getHttpOptionsTimeOffRequestApprove(token);
    let url = this.constant.API_URLS.GET_VACATION_REQUEST_FROM_EMAIL;
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  getVacationRequest(id: string): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_VACATION_REQUEST(id);
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  approveVacationRequest(payload: any, id: string): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.APPROVE_VACATION_REQUEST(id);
    return this.http.post<any>(this.baseUrl + url, payload, httpOptions);
  }

  rejectVacationRequest(payload: any, id: string): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.REJECT_VACATION_REQUEST(id);
    return this.http.post<any>(this.baseUrl + url, payload, httpOptions);
  }

  cancelVacationRequest(payload: any, id: string): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.CANCEL_VACATION_REQUEST(id);
    return this.http.post<any>(this.baseUrl + url, payload, httpOptions);
  }

  getAllEmployeeHistory(id: string): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_ALL_VACATION_HISTORY(id);
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  getAllEmployeeHistoryWOAccrued(id: string): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_ALL_VACATION_HISTORY_WO_ACCRUED(id);
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  getAllOrgHistory(id: string): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_ALL_ORG_VACATION_HISTORY(id);
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  getAllOrgHistoryWithAccrued(id: string): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_ALL_ORG_VACATION_HISTORY_ACCRUED(id);
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  getAllTeamEmployeeHistory(
    id: string,
    fromDate: string,
    toDate: string
  ): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_ALL_TEAM_VACATION_HISTORY(
      id,
      fromDate,
      toDate
    );
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  getOrganizationSchedulerData(id: string): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_ORGANIZATION_SCHEDULER_DATA(id);
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  getOrgSchedulerData(id: string): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_ORG_SCHEDULER_DATA(id);
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  getMyTeamSchedulerData(empId: string): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_MYTEAM_SCHEDULER_DATA(empId);
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  getApprovalRequest(id: string): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_APPROVAL_REQUEST(id);
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  getCountryStatDays(country: string, state: string): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_COUNTRY_STAT_DAYS(country, state);
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  addStatHoliday(payload: any, id: string): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.ADD_STAT_HOLIDAY(id);
    return this.http.post<any>(this.baseUrl + url, payload, httpOptions);
  }

  getCountryAndState(): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_COUNTRY_AND_STATE;
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  getVacationDays(fromDate, toDate): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_VACATION_DAYS(fromDate, toDate);
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  getRemainingVacationDays(): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_REMAINING_VACATION_DAYS;
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  getRemainingVacationDaysOnVacationDate(fromDate): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url =
      this.constant.API_URLS.GET_REMAINING_VACATION_DAYS_ON_VACATION_DATE(
        fromDate
      );
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  getemployeeRemainingVacationDaysOnVacationDate(
    employee,
    fromDate: string,
    allowEndDate: boolean,
    endDate: string = ""
  ): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url =
      this.constant.API_URLS.GET_EMPLOYEE_REMAINING_VACATION_DAYS_ON_VACATION_DATE(
        employee,
        fromDate,
        allowEndDate,
        endDate
      );
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  getemployeeRemainingVacationDays(employee: string): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url =
      this.constant.API_URLS.GET_EMPLOYEE_REMAINING_VACATION_DAYS(employee);
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  getUpcomingTimeOffs(id: string): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_UPCOMING_TIME_OFFS(id);
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }

  getAllOrganizationEmployees(id: string): Observable<any> {
    let httpOptions = this.getHttpOptions();
    let url = this.constant.API_URLS.GET_ORGANIZATION_EMPLOYEES(id);
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }
}
